import {
  Component,
  ElementRef,
  EventEmitter,
  NgZone,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TransitionService } from '@shared/services/transition-service.service';
import { MediaService } from '@shared/services/media.service';
import { Viewer } from 'marzipano';
import { app, media} from '@shared/data/data.json';
import { StateService } from '@shared/services/state.service';
import { Media } from '@models';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ModalDialogComponent } from '@shared/components/modal-dialog/modal-dialog.component';

/**
 * Component for viewpoint page. Viewpoints may have existing and proposed screens, night (dark) screen, and Easter Egg screen.
 * "Proposed" mode may also have layers (overlay, vegetation, etc).
 */
@Component({
  selector: 'app-media',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.scss'],
})
export class MediaComponent implements OnInit {
  @ViewChild('tiles') tiles: ElementRef;
  @Output() modalRef: BsModalRef;

  app: any;
  viewer: Viewer = {};
  type: string;
  mediaItem: Media;
  sceneHotspots: any = [];
  media: Media[];
  state: any;
  private refreshId: any;
  isShow: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private stateService: StateService,
    private router: Router,
    private modalService: BsModalService,
    private mediaService: MediaService,
  ) {
    // force route reload whenever params change
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.app = app;
  }

  ngOnInit() {
    this.media = media;
  }

  openDialog(media) {
    const initialState = {
      data: media,
    };
    this.modalRef = this.modalService.show(ModalDialogComponent, {
      initialState,
      class: 'modal-xl',
      keyboard: true,
      ignoreBackdropClick: false,
    });
  }

  toggle(){
    this.isShow = !this.isShow;
  }
  
  ngOnDestroy(): void {
    // this.viewer.destroy();
    this.stop();
  }
  /**
   * Starts the polling using in the compass and map viewcone rotation.
   */
  start() {
    let that = this;
    this.refreshId = setInterval(function () {
      let viewParams = that.viewer.view().parameters();
      // that.viewpointsService.onUpdateViewpoint.emit(that.viewpoint);
    }, 100);
  }

  /**
   * Clear the polling using in the compass and map viewcone rotation.
   */
  stop() {
    clearInterval(this.refreshId);
  }

}
