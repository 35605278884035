<div class="switch-container" [ngClass]="app.type === 'standalone' ? 'standalone' : 'embedded'">
  <label class="switch btn-color-mode-switch" [ngClass]="{'long-button': langCode === 'ht'}">
    <input type="checkbox" name="color_mode" id="color_mode" value="1" [checked]="checked" (change)="toggle()" />
    <label for="color_mode" [attr.data-on]="'proposed' | translate" [attr.data-off]="'existing' | translate"
      class="btn-color-mode-switch-inner "></label>
  </label>
  <div *ngIf="viewpoint.mode.proposed.layers" class="options" [ngClass]="this.checked ? 'isVisible' : ''">
    <div *ngFor="let layer of viewpoint.mode.proposed.layers.items" class="form-check">
      <input class="form-check-input" type="checkbox" [id]="layer.slug" [checked]="toggleState[layer.slug]?.checked"
        (change)="toggleLayer(layer.slug)" />
      <label class="form-check-label" [for]="layer.slug" [innerHTML]="layer.title"></label>
    </div>
  </div>
</div>