import { Component, Input, OnInit } from '@angular/core';
import { Viewpoint } from '@models/viewpoint.model';
import { Media } from '@shared/models/media.model';
import { app } from '@shared/data/data.json';

@Component({
  selector: 'app-horizontal-toolbar',
  templateUrl: './horizontal-toolbar.component.html',
  styleUrls: ['./horizontal-toolbar.component.scss']
})
export class HorizontalToolbarComponent implements OnInit {
  @Input('justifyLeft') justifyLeft: boolean = false;
  @Input('toggleBtnTop') toggleBtnTop: number = 87;
  @Input('title') title: string;
  @Input('viewpoint') currentViewpoint: Viewpoint;
  @Input('viewpoints') viewpoints: Viewpoint[];
  @Input('mediaItem') currentMedia: Media;
  @Input('media') media: Media[];
  @Input('lang') langCode: string;
  isAB: boolean = true;
  isA: boolean = true;
  isShow: boolean = false;
  app: any;
  constructor() {
    this.app = app;
  }

  ngOnInit(): void {
  }

  toggleAB() {
    this.isA = !this.isA;
  }

  toggle() {
    this.isShow = !this.isShow;
  }

}
