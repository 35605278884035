<div 
*ngIf="isShowWalkthrough" 
class="walkthrough" 
[ngClass]="data.transition === 'glide' ? 'glide' : ''" 
[ngStyle]="{
    top: currentStep.top ? 'calc(-98px + ' + currentStep.top + 'px)' : '',
    right: currentStep.right
      ? 'calc(-271px + ' + currentStep.right + 'px)'
      : '',
    bottom: currentStep.bottom
      ? 'calc(-128px + ' + currentStep.bottom + 'px)'
      : '',
    left: currentStep.left ? 'calc(-271px + ' + currentStep.left + 'px)' : ''
  }">
  <div class="walkthrough-inner">
    <div class="blob" (click)="togglePopup()"></div>
    <div 
    class="walkthrough-popup" 
    [ngClass]="[
        isShowPopup ? 'is-visible' : '',
        isRemind ? 'shake' : '',
        currentStep.popupVertAlign === 'top' ? 'pos-top fixed-height' : '',
        currentStep.popupVertAlign === 'bottom' ? 'pos-bottom' : '',
        currentStep.popupHorizAlign === 'left' ? 'pos-left' : '',
        currentStep.popupHorizAlign === 'right' ? 'pos-right' : ''
      ]">
      <p [innerHTML]="currentStep.text | translate"></p>
      <div class="walkthrough-footer">
        <h3 class="highlight" *ngIf="index < data.steps.length - 1" (click)="next()"
          [innerHTML]="(data.steps.length === 1 ? 'got' : 'next') | translate"></h3>
        <h4 (click)="done()" [ngClass]="[index === data.steps.length - 1 ? 'highlight' : '']"
          [innerHTML]="(data.steps.length === 1 ? 'got' : 'done') | translate"></h4>
      </div>
    </div>
  </div>
</div>