<div #tiles id="tiles"></div>
<div class="container">
  <div class="header-secondary" [ngClass]="viewpoint?.parent ? 'has-back-btn' : ''">
    <div class="tab" routerLink="/" [queryParams]="{lang: langCode}">
      <h3>{{'title.main' |lowercase |translate}}</h3>
    </div>
    <span *ngIf="viewpoint && viewpoint.parent" class="item numberplate">
      <span [innerHTML]="viewpoint.title"></span>
      <span class="position" [innerHTML]="viewpoint.roadsign.position"></span>
    </span>
    <span *ngIf="!viewpoint.parent" class="title" [ngClass]="app.type === 'standalone' ? 'standalone' : 'embedded'">
      <h2> {{'title.sub' |lowercase |translate}} </h2>
    </span>
  </div>
  <h1 class="heading" [innerHTML]="viewpoint.heading"></h1>
</div>

<app-back-button *ngIf="viewpoint.parent" [lang]="langCode"></app-back-button>
<app-switch *ngIf="viewpoint.mode['existing']" [viewpoint]="viewpoint" [checked]="state.global.mode === 'proposed'"
  (check)="toggleScene($event)" (checkLayer)="toggleLayerScene($event)" [lang]="langCode"></app-switch>
<app-dark-mode [top]="130" (toggleDarkScene)="toggleDarkScene($event)"
  *ngIf="type && viewpoint.mode[type].darkSceneSlug"></app-dark-mode>
<app-media *ngIf="!viewpoint.parent"></app-media>
<app-minimap [viewer]="viewer" [viewpoint]="viewpoint" [viewpoints]="viewpoints" [zoom]="16"></app-minimap>
<app-compass [viewpoint]="viewpoint" [viewer]="viewer"></app-compass>
<app-zoom-indicator [viewer]="viewer"></app-zoom-indicator>
<app-horizontal-toolbar [viewpoint]="viewpoint" [viewpoints]="viewpoints" [lang]="langCode"
  [title]="!viewpoint.parent ? 'location' : 'viewpoints'" [toggleBtnTop]="82"></app-horizontal-toolbar>
<app-hotspot *ngFor="let sceneHotspot of sceneHotspots" [scene]="sceneHotspot.scene" [data]="sceneHotspot.hotspot"
  [sceneId]="sceneHotspot.sceneId"></app-hotspot>
<app-walkthrough *ngIf="viewpoint.parent" [data]="walkthrough.externalViewpoints"></app-walkthrough>
<app-walkthrough *ngIf="!viewpoint.parent" [data]="walkthrough.home"></app-walkthrough>