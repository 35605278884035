import { Component, Input, OnInit } from '@angular/core';
import Marzipano from 'marzipano';

@Component({
  selector: 'app-zoom-indicator',
  templateUrl: './zoom-indicator.component.html',
  styleUrls: ['./zoom-indicator.component.scss'],
})
export class ZoomIndicatorComponent implements OnInit {
  private _viewer: Marzipano.Viewer;
  currentZoomLevel: number;
  displayZoomLevel: string;
  maxZoomOutLevel = 1;
  maxZoomInLevel = .5;

  constructor() {}
  @Input() set viewer(value: Marzipano.Viewer) {
    if (value._domElement) {
      this._viewer = value;
      this.currentZoomLevel = this.viewer.view().fov() + 0.1;
      this.viewer.view().setFov(this.currentZoomLevel);
      this.transformDisplayZoomLevel()
      let that = this;
      this.viewer.addEventListener('viewChange', function () {
        that.currentZoomLevel = that.viewer.view().fov();
        that.transformDisplayZoomLevel();
      });
    }
  }

  get viewer(): Marzipano.Viewer {
    return this._viewer;
  }
  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.viewer.removeEventListener('viewChange');
  }

  transformDisplayZoomLevel(){
    this.displayZoomLevel = this.currentZoomLevel.toFixed(1);
  }

  zoomOut(){
    if(this.currentZoomLevel < this.maxZoomOutLevel){
      this.currentZoomLevel+=0.1;
      this.viewer.view().setFov(this.currentZoomLevel);
      this.transformDisplayZoomLevel();
    }
    
  }

  zoomIn(){
    if(this.currentZoomLevel >= this.maxZoomInLevel){
      this.currentZoomLevel-=0.1;
      this.viewer.view().setFov(this.currentZoomLevel);
      this.transformDisplayZoomLevel();
    }
  }
}
