import { EventEmitter, Injectable, Output } from '@angular/core';
import { media } from '../data/data.json';

/**
 * Service for managing media. Assumes layer data is in "shared/data/data.json" in the following format:
 * {
  "media": [
    {
      "slug": "how-solar-works",
      ...
    }
  ]
}
 */
@Injectable({
  providedIn: 'root',
})
export class MediaService {
  media: any;
  @Output() onUpdateMedia = new EventEmitter();
  @Output() onChangeMedia = new EventEmitter();

  constructor() {
    this.media = media;
  }

  /**
   * Gets all Media
   * @returns Array Media
   */
  getAllMedia() {
    return this.media;
  }

  /**
   * Gets a Media item object by slug.
   * @param slug
   * @returns Object Media
   */
  getMedia(slug) {
    let media = null;
    for (let i = 0; i < this.media.length; i++) {
      const v = this.media[i];
      if (v.slug === slug) {
        media = v;
        break;
      }
    }
    //emit Media for header to receive
    this.onChangeMedia.emit(media);
    return media;
  }
}
