import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { ModalModule } from 'ngx-bootstrap/modal';
import { IvyCarouselModule } from 'angular-responsive-carousel';

import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { AppComponent } from './app.component';
import { ViewpointComponent } from './components/pages/viewpoint/viewpoint.component';
import { HorizontalToolbarComponent } from './shared/components/horizontal-toolbar/horizontal-toolbar.component';
import { FooterComponent } from './shared/components/footer/footer.component';
import { HeaderComponent } from './shared/components/header/header.component';
import { CompassComponent } from './shared/components/compass/compass.component';
import { MinimapComponent } from './shared/components/minimap/minimap.component';
import { BackButtonComponent } from './shared/components/back-button/back-button.component';
import { SwitchComponent } from './shared/components/switch/switch.component';
import { ModalDialogComponent } from './shared/components/modal-dialog/modal-dialog.component';
import { AppRoutingModule } from './app-routing.module';
import { TsLogoComponent } from './shared/components/ts-logo/ts-logo.component';
import { HotspotComponent } from './shared/components/hotspot/hotspot.component';
import { SocialLinksComponent } from './shared/components/social-links/social-links.component';
import { NavComponent } from './shared/components/nav/nav.component';
import { SafeHtmlPipe } from './shared/pipes/safe-html.pipe';
import { AbTestComponent } from './shared/components/ab-test/ab-test.component';
import { DarkModeComponent } from './shared/components/dark-mode/dark-mode.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { WalkthroughComponent } from './shared/components/walkthrough/walkthrough.component';
import { EasterEggComponent } from './shared/components/easter-egg/easter-egg.component';
import { ZoomIndicatorComponent } from './shared/components/zoom-indicator/zoom-indicator.component';
import { MediaComponent } from '@pages/media/media.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    ViewpointComponent,
    MediaComponent,
    HorizontalToolbarComponent,
    FooterComponent,
    HeaderComponent,
    CompassComponent,
    MinimapComponent,
    BackButtonComponent,
    SwitchComponent,
    ModalDialogComponent,
    TsLogoComponent,
    HotspotComponent,
    SocialLinksComponent,
    NavComponent,
    SafeHtmlPipe,
    AbTestComponent,
    DarkModeComponent,
    WalkthroughComponent,
    EasterEggComponent,
    ZoomIndicatorComponent
  ],
  imports: [
    BrowserModule,
    NoopAnimationsModule,
    LeafletModule,
    AppRoutingModule,
    IvyCarouselModule,
    HttpClientModule,
    ModalModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy }],
  bootstrap: [AppComponent]
})
export class AppModule { }
