import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ViewpointComponent } from '@pages/viewpoint/viewpoint.component';

const routes: Routes = [
  { path: '', redirectTo: 'viewpoint', pathMatch: 'full' },
  { path: 'viewpoint', component: ViewpointComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }