import { Component, Input, OnInit } from '@angular/core';
import { app } from '@shared/data/data.json';

@Component({
  selector: 'app-back-button',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.scss']
})
export class BackButtonComponent implements OnInit {

  @Input('pageName') pageName: string = '';
  @Input('lang') langCode: string;

  app: any;

  constructor() {
    this.app = app;
  }

  ngOnInit(): void {
  }

}
